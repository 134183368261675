enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  BERKAS_STORE = "berkasStore",
  BERKAS_UPDATE = "berkasUpdate",
  BERKAS_DELETE = "berkasDelete",
  OPERATOR_GET_ALL = "operatorGetAll",
  GET_ONE = "getOne",
  OPERATOR_ADD = "operatorAdd",
  OPERATOR_UPDATE = "operatorUpdate",
  OPERATOR_PROFILE_UPDATE = "operatorCompleting",
  OPERATOR_CHANGE_PASSWORD = "operatorChangePassword",
  OPERATOR_BAN = "operatorBan",
  OPERATOR_DELETE = "operatorDelete",
  PELATIHAN_STORE = "pelatihanStore",
  PELATIHAN_UPDATE = "pelatihanUpdate",
  PELATIHAN_DELETE = "pelatihanDelete",
  PENGHARGAAN_STORE = "penghargaanStore",
  PENGHARGAAN_UPDATE = "penghargaanUpdate",
  PENGHARGAAN_DELETE = "penghargaanDelete",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_URL = "url",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  TABLE_DATA = "tableData",
  FORM_DATA = "formData",
}

export { Actions, Mutations };
