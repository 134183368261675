import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BerkasModule from "@/store/modules/BerkasModule";
import PelatihanModule from "@/store/modules/PelatihanModule";
import PenghargaanModule from "@/store/modules/PenghargaanModule";
import OperatorModule from "@/store/modules/OperatorModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BerkasModule,
    ConfigModule,
    ThemeModeModule,
    OperatorModule,
    PelatihanModule,
    PenghargaanModule,
  },
});

export default store;
