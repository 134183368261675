import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Operator {
  user_id: string;
  user_fullname: string;
  user_email: string;
  user_password: string;
  user_role: string;
  user_status: string;
  user_profile: string;
  user_created_at: string;
  user_updated_at: string;
  user_deleted_at: string;
}

export interface OperatorInfo {
  allData: Operator;
  // oneData: Operator;
}

@Module
export default class OperatorModule extends VuexModule implements OperatorInfo {
  allData = {} as Operator;
  // oneData = {} as Operator;

  /**
   * Get current data object
   * @returns Data
   */
  get allOperator(): Operator {
    return this.allData;
  }
  // get oneOperator(): Operator {
  //   return this.oneData;
  // }

  @Mutation
  [Mutations.TABLE_DATA](data) {
    this.allData = data;
  }
  // @Mutation
  // [Mutations.SET_URL](data) {
  //   this.oneData = data;
  // }

  @Action
  [Actions.OPERATOR_GET_ALL]() {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      ApiService.getWithToken("users", {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.TABLE_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_ADD](formData) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      ApiService.postWithToken("/users/create", formData, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_UPDATE](data) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      const id = data.id;
      const formData = data.formData;
      ApiService.putWithToken("users/update?id=" + id, formData, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_PROFILE_UPDATE](data) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      const id = data.id;
      const formData = data.formUpload;
      ApiService.putWithToken("users/activating?id=" + id, formData, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_CHANGE_PASSWORD](data) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      const id = data.id;
      const formData = data.formData;
      ApiService.putWithToken("users/changepassword?id=" + id, formData, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_BAN](id) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      console.log(id);
      console.log(api_token);
      ApiService.putWithToken("users/?id=" + id, id, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          console.log(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.OPERATOR_DELETE](id) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      console.log(id);
      console.log(api_token);
      ApiService.putWithToken("users/banning/?id=" + id, id, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          console.log(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
