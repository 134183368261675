import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/profile-detail",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/profile-menu",
        name: "profile-menu",
        component: () => import("@/views/profile/ProfileMenu.vue"),
        meta: {
          pageTitle: "Profile Menu",
          breadcrumbs: ["Profile", "Menu"],
        },
        children: [
          {
            path: "/profile-detail",
            name: "profile-detail",
            component: () => import("@/views/profile/ProfileDetail.vue"),
            meta: {
              pageTitle: "Profile",
              breadcrumbs: ["Profile", "Detail"],
            },
          },
          {
            path: "/profile-settings",
            name: "profile-settings",
            component: () => import("@/views/profile/ProfileSettings.vue"),
            meta: {
              pageTitle: "Profile",
              breadcrumbs: ["Profile", "Print"],
            },
          },
        ],
      },
      // {
      //   path: "/profile-edit/:id",
      //   name: "profile-edit",
      //   component: () => import("@/views/profile/ProfileForm.vue"),
      //   meta: {
      //     pageTitle: "Edit Profile",
      //     breadcrumbs: ["Profile", "Edit"],
      //   },
      // },
      {
        path: "/berkas-menu",
        name: "berkas-menu",
        component: () => import("@/views/berkas/BerkasMenu.vue"),
        meta: {
          pageTitle: "Berkas Menu",
          breadcrumbs: ["Berkas", "Menu"],
        },
      },
      {
        path: "/berkas-menu",
        name: "berkas-menu",
        component: () => import("@/views/berkas/BerkasMenu.vue"),
        meta: {
          pageTitle: "Berkas Menu",
          breadcrumbs: ["Berkas", "Menu"],
        },
      },
      {
        path: "/berkas-add",
        name: "berkas-add",
        component: () => import("@/views/berkas/BerkasForm.vue"),
        meta: {
          pageTitle: "Add Berkas",
          breadcrumbs: ["Berkas", "Add"],
        },
      },
      {
        path: "/berkas-edit/:id",
        name: "berkas-edit",
        component: () => import("@/views/berkas/BerkasForm.vue"),
        meta: {
          pageTitle: "Edit Berkas",
          breadcrumbs: ["Berkas", "Edit"],
        },
      },
      {
        path: "/pelatihan-menu",
        name: "pelatihan-menu",
        component: () => import("@/views/pelatihan/PelatihanMenu.vue"),
        meta: {
          pageTitle: "Pelatihan Menu",
          breadcrumbs: ["Pelatihan", "Menu"],
        },
      },
      {
        path: "/pelatihan-add",
        name: "pelatihan-add",
        component: () => import("@/views/pelatihan/PelatihanForm.vue"),
        meta: {
          pageTitle: "Add Pelatihan",
          breadcrumbs: ["Pelatihan", "Add"],
        },
      },
      {
        path: "/pelatihan-edit/:id",
        name: "pelatihan-edit",
        component: () => import("@/views/pelatihan/PelatihanForm.vue"),
        meta: {
          pageTitle: "Edit Pelatihan",
          breadcrumbs: ["Pelatihan", "Edit"],
        },
      },
      {
        path: "/penghargaan-menu",
        name: "penghargaan-menu",
        component: () => import("@/views/penghargaan/PenghargaanMenu.vue"),
        meta: {
          pageTitle: "Penghargaan Menu",
          breadcrumbs: ["Penghargaan", "Menu"],
        },
      },
      {
        path: "/penghargaan-add",
        name: "penghargaan-add",
        component: () => import("@/views/penghargaan/PenghargaanForm.vue"),
        meta: {
          pageTitle: "Add Penghargaan",
          breadcrumbs: ["Penghargaan", "Add"],
        },
      },
      {
        path: "/penghargaan-edit/:id",
        name: "penghargaan-edit",
        component: () => import("@/views/penghargaan/PenghargaanForm.vue"),
        meta: {
          pageTitle: "Edit Penghargaan",
          breadcrumbs: ["Penghargaan", "Edit"],
        },
      },
      {
        path: "/operator-menu",
        name: "operator-menu",
        component: () => import("@/views/operator/OperatorMenu.vue"),
        meta: {
          pageTitle: "Operator Menu",
          breadcrumbs: ["Operator", "Menu"],
        },
      },
      {
        path: "/operator-add",
        name: "operator-add",
        component: () => import("@/views/operator/OperatorForm.vue"),
        meta: {
          pageTitle: "Add Operator",
          breadcrumbs: ["Operator", "Add"],
        },
      },
      {
        path: "/operator-edit/:id",
        name: "operator-edit",
        component: () => import("@/views/operator/OperatorForm.vue"),
        meta: {
          pageTitle: "Edit Operator",
          breadcrumbs: ["Operator", "Edit"],
        },
      },
      {
        path: "/operator-view/:id",
        name: "operator-view",
        component: () => import("@/views/operator/OperatorDetail.vue"),
        meta: {
          pageTitle: "Operator View",
          breadcrumbs: ["Operator", "View"],
        },
      },
    ],
  },

  {
    path: "/profile-print",
    component: () => import("@/views/profile/ProfilePrint.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/operator-print/:id",
    component: () => import("@/views/operator/OperatorPrint.vue"),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/authentication/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () => import("@/views/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
