import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class PenghargaanModule extends VuexModule {
  @Action
  [Actions.PENGHARGAAN_STORE](formData) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      ApiService.postWithToken("penghargaan", formData, {
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.PENGHARGAAN_UPDATE](data) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      const id = data.id;
      const formData = data.formData;
      ApiService.putWithToken("penghargaan/?id=" + id, formData, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.PENGHARGAAN_DELETE](id) {
    if (JwtService.getToken()) {
      const api_token = JwtService.getToken();
      ApiService.delWithToken("penghargaan/?id=" + id, {
        headers: { Authorization: "Bearer " + api_token },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
